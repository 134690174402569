<template>
  <div class="body">
    <Head :is-url="'true'" @click="goPrev"></Head>
    <div>
      <div style="width: 100%" v-for="(item, index) in urls" :key="index">
        <img style="width: 100%; height: 100%" :src="item.src" alt="" />
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "mediapage",
  components: { Head, Foot },
  data() {
    return {
      urls: [],
    };
  },
  created() {
    window.scroll(0, 0);
    this.urls = this.$route.query.url;
    window.console.log(this.urls);
  },
  mounted() {
    // this.urls = localStorage.getItem('urls')
    // // JSON.parse(this.urls)
    // window.console.log(localStorage.getItem('urls')[0].url)
  },
  methods: {
    goPrev: function () {
      this.$router.push("/internet");
    },
  },
};
</script>

<style lang="scss" scoped></style>
